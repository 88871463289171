<template>
  <div class="collect-table">
    <template v-if="list.length > 0">
      <el-table
        :data="list"
        style="width: 100%"
        max-height="660"
        v-loading="loading"
      >
        <el-table-column label="课程名称" width="820">
          <template slot-scope="scope">
            <span style="cursor: pointer" @click="nrmkldkahLook(scope.row)">
              {{ scope.row.activityName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="性质" align="center" width="110">
          <template slot="header">
            <el-dropdown @command="handleCommand">
              <span
                class="el-dropdown-link"
                style="font-size: 16px; color: #363636; font-weight: bold"
              >
                {{ radio }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="ACTIVITY">课程</el-dropdown-item>
                <el-dropdown-item command="INFORMATION">资讯</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.tag | course_classification }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="storeTime"
          label="收藏时间"
          align="center"
          width="140"
        >
          <template slot-scope="scope">
            <span class="text">{{
              scope.row.storeTime | timeFormatter("{year}年{month}月{day}日")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="110">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="cancelCollectionIncident(scope.row)"
            >
              取消收藏
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page" v-if="currentTotal > 10">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="currentTotal"
        >
        </el-pagination>
      </div>
    </template>
    <template v-else>
      <AbsolutelyEmpty title="暂无收藏哦！" :img="collectImg"></AbsolutelyEmpty>
    </template>
  </div>
</template>
<script>
import Utils from '@/utils';
import list from '@/mixins/list';
import { timeFormatter } from '@/utils/verify';
import { personalCenterMyCollect } from '@/api/personal';
import { course_classification } from '@/config/statusCode';
import collectImg from '@/assets/images/personage/sho-chang.png';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import {
  activityDetailCollect,
  informationStoreInformationId
} from '@/api/api';
export default {
  name: 'CollectTable',
  components: {
    AbsolutelyEmpty
  },
  filters: {
    timeFormatter,
    course_classification
  },
  mixins: [list],
  props: {
    personageTime: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      radio: '课程',
      collectType: 'ACTIVITY',
      collectImg: collectImg
    };
  },
  watch: {
    personageTime(vol) {
      this.pageNum = 1;
      this.getPersonalCenterMyCollect();
    }
  },
  methods: {
    getPersonalCenterMyCollect() {
      const jsonData = this.getParams();
      jsonData.time = this.personageTime;
      jsonData.collectType = this.collectType;
      personalCenterMyCollect(jsonData).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          this.currentTotal = total;
          this.list = list;
          this.loading = false;
        }
      });
    },
    handleCommand(command) {
      if (command == 'ACTIVITY') {
        this.radio = '课程';
      } else {
        this.radio = '资讯';
      }
      this.collectType = command;
      this.pageNum = 1;
      this.getPersonalCenterMyCollect();
    },
    // 分页
    loadData() {
      this.getPersonalCenterMyCollect();
    },
    cancelCollectionIncident(e) {
      this.$trainPopup().then((res) => {
        if (res.code == 200) {
          this.$loading();
          if (this.collectType == 'ACTIVITY') {
            activityDetailCollect({ activityId: e.id }).then((res) => {
              if (res.code == 200) {
                setTimeout(() => {
                  this.$loading().close();
                  this.pageNum = 1;
                  this.getPersonalCenterMyCollect();
                  Utils.msgSuccess('取消收藏成功');
                }, 1500);
              } else {
                this.$loading().close();
              }
            });
          } else if (this.collectType == 'INFORMATION') {
            informationStoreInformationId({ informationId: e.id }).then(
              (res) => {
                if (res.code == 200) {
                  setTimeout(() => {
                    this.$loading().close();
                    this.pageNum = 1;
                    this.getPersonalCenterMyCollect();
                    Utils.msgSuccess('取消收藏成功');
                  }, 1500);
                } else {
                  this.$loading().close();
                }
              }
            );
          }
        }
      });
    },
    // 跳转到学习详情
    nrmkldkahLook(e) {
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: e.id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.collect-table {
  min-height: 450px;
  margin-top: 32px;
  width: 100%;
  .page {
    height: 138px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-bottom: 90px;
    margin-top: 40px;
    /deep/.number,
    /deep/.btn-prev,
    /deep/.btn-next {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      &.active {
        background: @TEXT-BG-00BD92 !important;
      }
    }
    /deep/.el-pager li:hover {
      color: @TEXT-COLOR-00BD92;
    }
    /deep/.el-pager li.active:hover {
      color: @TEXT-COLOR-FFFFFF;
    }
  }
  /deep/.el-table th,
  .el-table tr {
    background: #f4f4f4;
    text-align: center;
    font-size: 16px;
    color: #363636;
    font-weight: bold !important;
  }
  /deep/.el-table th:first-child,
  .el-table tr:first-child {
    text-align: left;
  }
  /deep/.el-table__row,
  /deep/.has-gutter,
  /deep/.el-table__header,
  /deep/.el-table__header-wrapper {
    height: 60px;
  }
  /deep/ tr {
    height: 60px;
  }
  /deep/ tr th {
    height: 60px;
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #00bd92 !important;
}
</style>
