<template>
  <div class="personage-time">
    <!-- v-on:isToday="clickToday"  -->
    <calendar
      v-on:choseDay="clickDay"
      v-on:changeMonth="changeDate"
      :markDateMore="arr"
      :sundayStart="true"
    >
    </calendar>
  </div>
</template>
<script>
import Utils from '@/utils';
import Calendar from 'vue-calendar-component';
import { personalCenterTimetable } from '@/api/personal';
export default {
  name: 'PersonageTime',
  components: {
    Calendar
  },
  data() {
    return {
      arr: [],
      time: ''
    };
  },
  created() {
    const time = new Date().getTime();
    this.time = Utils.getLocalTime(time, 'month');
    this.init();
  },
  methods: {
    init() {
      const jsonData = {
        time: this.time
      };
      personalCenterTimetable(jsonData).then((res) => {
        var obj = {};
        const arr = [];
        this.arr = [];
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i++) {
            obj = {
              date: res.data[i],
              className: 'mark1'
            };
            arr.push(obj);
          }
          this.arr = arr;
        }
      });
    },
    allTimeData(arr) {
      for (let index = 0; index < arr.length; index++) {
        if (new Date(arr[index].date).getTime() == this.activeTime) {
          arr[index].className = 'mark2';
        } else {
          arr[index].className = 'mark1';
        }
      }
      return arr;
    },
    clickDay(data) {
      this.activeTime = new Date(data).getTime();
      const time = Utils.getLocalTime(this.activeTime);
      this.arr = this.allTimeData(this.arr);
      this.$emit('clickDay', { time });
    },
    changeDate(data) {
      const time = new Date(data).getTime();
      this.time = Utils.getLocalTime(time, 'month');
      this.init();
    }
  }
};
</script>
<style lang="less" scoped>
.personage-time {
  padding-top: 50px;
  padding-bottom: 20px;
  /deep/.wh_content {
    padding: 0;
    margin: 0;
  }
  /deep/.wh_container {
    max-width: 896px !important;
    box-sizing: border-box;
    margin: 0 !important;
    overflow: hidden;
  }
  /deep/.wh_content_all {
    background: #fff;
    padding: 0;
  }
  /deep/.wh_jiantou1,
  /deep/.wh_jiantou2 {
    margin: 0;
    padding: 0;
    width: 23px;
    height: 23px;
    border-top: none;
    border-left: none;
    transform: rotate(0deg);
    background: url("~@/assets/images/personage/arrows-z.png") no-repeat 0px 0px;
    overflow: hidden;
  }
  /deep/.wh_jiantou2 {
    width: 100%;
    background: #fff url("~@/assets/images/personage/arrows-y.png") no-repeat
      0px 0px;
    border-right: none;
  }
  // 左右
  /deep/.wh_top_changge li {
    color: #00bd92;
    font-size: 18px;
    margin-bottom: 40px;
  }
  /deep/.wh_top_changge li:first-child {
    justify-content: flex-end;
  }
  /deep/.wh_top_changge li:last-child {
    justify-content: flex-start;
  }
  /deep/.wh_top_changge {
    color: #363636;
  }
  // 下方数字
  /deep/.wh_content_item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363636;
    /deep/.wh_top_tag {
      padding: 0;
      margin: 0;
      flex: 1;
      height: 50px;
      background: #f4f4f4;
    }
  }
  /deep/.wh_content:last-child {
    background: #fff;
    margin-top: 30px;
    /deep/.wh_content_item {
      background: #fff;
      width: 113px;
      height: 80px;
      box-sizing: border-box;
      margin-right: 8px;
      margin-bottom: 8px;
      /deep/.wh_item_date {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: 1px solid #f4f4f4;
      }
      /deep/.wh_item_date:hover {
        background: #f4f4f4;
      }
    }
  }
  /deep/.wh_content_item {
    position: relative;
  }
  /deep/.mark1:after {
    content: " ";
    background: #00bd92;
    font-size: 10px;
    color: #00bd92;
    position: absolute;
    bottom: 12px;
    left: 50%;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
  /deep/.mark2:after {
    content: " ";
    font-size: 10px;
    color: #fff;
    background: #fff;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
  /deep/.wh_content_item .wh_other_dayhide,
  /deep/.wh_content_item .wh_want_dayhide {
    color: #363636;
  }
  // 按钮选中
  /deep/.wh_content_item .wh_chose_day {
    background: #00bd92 !important;
    color: #fff;
  }
  /deep/.wh_content_item .wh_isToday {
    background: #fff;
  }
  /deep/.wh_content_item .wh_isToday:hover {
    background: #71c7a5;
  }
  /deep/.wh_top_changge .wh_content_li {
    flex: 1;
  }
}
</style>