<template>
  <div class="personage-list">
    <template v-if="personageList.length > 0">
      <div
        class="personage-box con"
        v-for="(itme, index) in personageList"
        :key="index"
        @click="courseRecommendedIncident(itme, index)"
      >
        <div class="bg">
          <img :src="itme.imgUrl" alt="" />
          <div
            class="pa flex conet"
            v-if="itme.tag == 'LIVE_BROADCAST' && itme.tag != null"
          >
            {{ itme.liveTag | live_tag }}
          </div>
        </div>
        <div class="personage-btm con j-w">
          <div class="con">
            <div class="title">{{ itme.activityName }}</div>
            <div class="text top">{{ itme.tag | course_classification }}</div>
          </div>
          <div class="flex j-w conet">
            <div class="text">{{ itme.holdUpSeats || 0 }}人已报名</div>
            <div class="text flex conet" v-if="itme.studyHour > 0">
              <span class="span">{{ itme.studyHour }}</span> <span>学时</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <AbsolutelyEmpty></AbsolutelyEmpty>
    </template>
  </div>
</template>
<script>
import { course_classification, live_tag } from '@/config/statusCode';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
export default {
  name: 'PersonageList',
  components: {
    AbsolutelyEmpty
  },
  filters: {
    course_classification,
    live_tag
  },
  props: {
    personageList: {
      type: Array,
      default: []
    }
  },
  methods: {
    // 课程推荐跳转
    courseRecommendedIncident(itme, index) {
      this.$router.push({
        path: '/courseDetails',
        query: {
          id: itme.id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.personage-list {
  box-sizing: border-box;
  height: 640px;
  overflow-y: scroll;
  margin-top: 45px;
  padding-right: 5px;
  .personage-box {
    height: 300px;
    width: 280px;
    margin-bottom: 20px;
    border: 1px solid @TEXT-BG-E6E6E6;
    cursor: pointer;
    .bg {
      position: relative;
      height: 158px;
      width: 100%;
      overflow: hidden;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        transition: all 0.6s linear;
      }
      .pa {
        padding: 0 20px;
        box-sizing: border-box;
        height: 28px;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
        .fontSize(@TEXT-SIZE-12);
        color: @TEXT-COLOR-FFFFFF;
        background: @TEXT-BG-00BD92;
      }
    }
    .personage-btm {
      padding: 15px;
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      .title {
        .fontSize(@TEXT-SIZE-16);
        font-weight: 400;
        line-height: 26px;
      }
      .text {
        .fontSize(@TEXT-SIZE-14);
        color: @TEXT-COLOR-888888;
        .span {
          .fontSize(@TEXT-SIZE-24);
          color: @TEXT-COLOR-FF5102;
          margin-right: 5px;
        }
      }
      .top {
        margin-top: 8px;
      }
      .ls {
        width: 20px;
        height: 20px;
        background: #000;
        margin-right: 5px;
      }
    }
  }
  .personage-box:hover .personage-btm {
    transition: all 0.15s linear;
    box-shadow: 0 15px 35px 0 rgba(27, 31, 46, 0.09);
  }
  .personage-box:hover .bg img {
    transform: scale(1.1);
  }
  .personage-box:hover .personage-btm .title {
    color: #00bd92;
    transition: all 0.2s linear;
  }
  .absolutely-empty {
    padding-top: 30px;
  }
}
</style>

