<template>
  <div class="personage">
    <PersonageNav :personageTime="personageTimes"></PersonageNav>
    <PersonageTabs :list="list" :scheduleIndex.sync="scheduleIndex">
    </PersonageTabs>
    <div class="container">
      <div class="container-fluid">
        <div class="learn-box" v-if="scheduleIndex != 0">
          <div></div>
          <div class="learn-rig">
            <div class="text">年度：</div>
            <el-select
              v-model="personageTimes"
              placeholder="请选择"
              @change="changeIncident"
            >
              <el-option
                style="color: #363636; font-weight: normal"
                v-for="item in optionsList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="box">
          <template v-if="scheduleIndex == 0">
            <div class="list flex j-w">
              <PersonageTime @clickDay="clickDay"></PersonageTime>
              <PersonageList :personageList="personageList"></PersonageList>
            </div>
          </template>
          <div
            class="list flex"
            v-for="(itme, index) in scheduleList"
            :key="index"
          >
            <template v-if="scheduleIndex == itme.scheduleIndex">
              <component
                :is="itme.component"
                :personageTime="personageTimes"
              ></component>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import PersonageNav from './components/personage-nav';
import Period from './components/period';
import SignIn from './components/sign-in';
import StudyTable from './components/study-table';
import CollectTable from './components/collect-table';
import PersonageTabs from './components/personage-tabs';
import PersonageTime from './components/personage-time';
import PersonageList from './components/personage-list';
import { personalCenterTimetableCourse } from '@/api/personal';
import Config from '@/config/index';
const dateTime = new Date().getFullYear();
export default {
  name: 'Personage',
  components: {
    PersonageNav,
    PersonageTabs,
    PersonageTime,
    PersonageList,
    Period: Period,
    SignIn: SignIn,
    StudyTable: StudyTable,
    CollectTable: CollectTable
  },
  data() {
    return {
      scheduleIndex: -1,
      list: Config.PERSONAGE_ARR,
      personageList: [],
      personageTimes: dateTime,
      scheduleList: [
        {
          scheduleIndex: 1,
          component: 'Period'
        },
        {
          scheduleIndex: 2,
          component: 'SignIn'
        },
        {
          scheduleIndex: 3,
          component: 'StudyTable'
        },
        {
          scheduleIndex: 4,
          component: 'CollectTable'
        }
      ]
    };
  },
  computed: {
    optionsList() {
      const arr = [];
      for (let i = 0; i <= 5; i++) {
        var obj = {
          value: dateTime - i,
          label: dateTime - i
        };
        arr.push(obj);
      }
      return arr;
    }
  },
  watch: {
    scheduleIndex(val) {
      const { type } = this.$route.query;
      this.replaceRouter(val, type);
      this.scheduleIncident();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const { type } = this.$route.query;
      if (type) {
        this.scheduleIndex = parseInt(type);
      } else {
        this.scheduleIndex = 0;
      }
      this.timetableCourseall();
    },
    // 选择时间
    changeIncident(e) {
      this.personageTimes = e;
    },
    scheduleIncident() {
      this.personageTimes = new Date().getFullYear();
      this.timetableCourseall();
    },
    timetableCourseall() {
      if (this.scheduleIndex != 0) return;
      const time = new Date().getTime();
      this.getPersonalCenterTimetableCourse(Utils.getLocalTime(time));
    },
    replaceRouter(date, parameter) {
      if (parameter == date) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, type: date }
      });
    },
    // 请求当天的课程
    clickDay(e) {
      this.getPersonalCenterTimetableCourse(e.time);
    },
    getPersonalCenterTimetableCourse(time) {
      const jsonData = {
        time: time
      };
      personalCenterTimetableCourse(jsonData).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          this.personageList = list;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.personage {
  box-sizing: border-box;
  .container-fluid {
    padding: 0;
    overflow: hidden;
  }
  .box {
    width: 100%;
    transition: all 0.15s linear;
    .list {
      width: 100%;
      padding: 0 10px;
      background: #ffffff;
    }
  }
}
@import "~@/assets/style/learn";
.learn-box {
  margin-top: 32px;
}
</style>